<template>
  <div class="managingPeople hp100 bbox">
    <!-- 卡片视图 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around" style="margin-top: 15px;">
        <el-col :span="18" class="topBtn">
          <el-checkbox style="margin-right: 5px;" v-model="checkAll"></el-checkbox>
          <el-button type="primary" @click="batchDelete">批量删除</el-button>
          <el-button type="primary" @click="pictureUpload('add', undefined)"><i class="el-icon-upload2"></i>
            上传</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getColorList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table border ref="table" :data="pictureList" tooltip-effect="dark" style="width: 100%" :height="height"
        @selection-change="handleSelectionChange">
        <el-table-column label="选中删除" align="center" show-overflow-tooltip v-if="checkAll" type="selection">
        </el-table-column>
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="色卡名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.cardName }}</template>
        </el-table-column>
        <el-table-column label="色卡图" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 80px;" :preview-src-list="scope.row.fileUrl.split(',')"
              :src="scope.row.fileUrl">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button type="text" @click="pictureUpload('edit', scope.row)">色卡替换</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[10, 20, 30, 40]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 上传图片 -->
    <el-dialog :title="isEdit ? '色卡替换' : '色卡上传'" :visible.sync="pictureDialog" width="30%" destroy-on-close>
      <el-form ref="uploadColor" :model="form">
        <el-form-item prop="cardPictureList">
          <el-upload :action="$uploadURL" list-type="picture-card" :on-preview="previewImg" :on-remove="removeImg"
            :on-success="uploadImg" :limit="fileLimit" :multiple="true">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="isPreview">
        <el-image width="100%" :src="previewItem" alt="" />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closePicture">取 消</el-button>
        <el-button type="primary" @click="submitPicture">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictureList: [], // 图片渲染
      pictureDialog: false,
      isPreview: false,
      // 预览的图片
      previewItem: "",
      cardPictureList: [],
      // 文件上传数量限制
      fileLimit: 10,
      // 文件类型
      fileType: "",
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {
        fileUrl: ''
      },
      height: window.innerHeight - 200, // 表格高度
      total: 0,
      checkAll: false,
      checkedVideoes: [],   // 选中的id
      cardNameList: [],
      fileUrl: [],
      isEdit: false
    };
  },
  created() {
    this.getColorList();
  },
  // computed: {
  //     // 是否显示提示
  //     showTip() {
  //       return this.isShowTip && (this.fileType || this.fileSize);
  //     },
  //   },
  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },
    // 获取色卡列表
    getColorList() {
      var that = this;
      // let previewList = []
      that.$http
        .post("/colorCardManage/list", that.queryInfo)
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.pictureList = response.data.data.data;
            that.total = response.data.data.count;
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getColorList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getColorList();
    },
    // 上传按钮
    pictureUpload(flags, item) {
      if (flags == 'add') {
        this.isEdit = false
      } else {
        this.isEdit = true

        console.log(item);
      }
      this.pictureDialog = true;
      this.checkAll = false
    },

    // 关闭表单
    closePicture() {
      // console.log(this.$refs.uploadColor);
      // this.$refs.uploadColor.resetFields();
      this.form.fileUrl = ''
      this.pictureDialog = false;
    },

    // 确定按钮
    submitPicture() {
      var that = this;
      if (this.isEdit) {


      } else {
        that.$http.post("/colorCardManage/saveBatch", that.cardPictureList)
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "保存成功",
                showClose: true,
              });
              that.pictureDialog = false;
              that.getColorList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          }).catch(function (error) {


          })
      }
    },

    // 预览图片
    previewImg(file) {
      // console.log(file);
      this.isPreview = true;
      this.previewItem = file.url;
    },

    // 删除图片
    removeImg(res, file, fileList) {
      // console.log(res);
      const index = this.cardPictureList.findIndex((v) => {
        return v.fileName === res.fileName;
      });
      this.cardPictureList.splice(index, 1);
    },

    uploadImg(res, file, fileList) {
      console.log("res", res);
      console.log("fileList", fileList);
      // console.log(this.cardPictureList);
      this.cardPictureList.push(res.data);
    },
    // 单个选中
    handleSelectionChange(val) {
      console.log(val);
      let colorTabList = []
      this.cardNameList = []
      colorTabList = val
      colorTabList.forEach(item => {
        this.cardNameList.push(item.cardName)
      })
      console.log(this.cardNameList);
    },

    async batchDelete(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("/colorCardManage/deleteBatch", { cardNameList: this.cardNameList }).then(function (response) {
          if (response.data.code == 200) {
            // console.log(response);
            that.checkAll = false
            that.$notify.success({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
            that.getColorList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
  }
}

.topTitle {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;

  .el-button {
    font-size: 17px;
  }
}

.btmPicture {
  width: 100%;
  min-height: 500px;
  background-color: pink;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 20px;
  overflow-y: "auto";

  .pictureImg {
    width: 180px;
    min-height: 160px;
    background-color: blue;
    text-align: center;
    margin-right: 20px;
    margin-top: 10px;

    img {
      width: 100%;
    }

    p {
      margin-top: 5px;
    }
  }
}

.cardImg {
  width: 80px;
  height: 80px;
}
</style>
